import styles from "./Layout.module.scss"
import cote from "../../assets/img/cote.png"
import logo from "../../assets/img/logo.png"
import logoLong from "../../assets/img/logo_stoned_egod_B.png"
import logoConext from "../../assets/img/conext-logo.svg"
import discord from "../../assets/img/icons8-discord.svg"
import twitter from "../../assets/img/icons8-twitter.svg"
import Button from "../Button"
import { useGetLoginInfo,logout } from '@elrondnetwork/dapp-core';


function Layout({children}){
    const {isLoggedIn} = useGetLoginInfo();
    return(
        <div className={styles.layout}>
            <img src={cote} alt="cotes"/>
            <img src={cote} alt="cotes"/>
            <div className={styles.header}>
                <img src={logo} alt="logo"/>
                <h2>Stoned eGod Staking</h2>
                <div className={styles.break}></div>
                {isLoggedIn && <Button onClick={logout} className={styles.button}>Logout</Button>}
            </div>
            <div className={styles.body}>
                {children}
            </div>
            <div className={styles.footer}>
                <div className={styles.box}><span>Created with {'\u2665'} by<a href="https://conext-agence.com"><img src={logoConext} alt="Conext développement blokchain"/></a></span></div>
                <div className={styles.break + " " + styles.box}></div>
                <div className={styles.box}><span><a href="https://stonedegod.com/"><img className={styles.box} src={logoLong} alt="Logo Stoned eGod"/></a></span></div>
                <div className={styles.box}><span><a href="https://discord.gg/mfQWuCcyqz"><img src={discord} alt="Discord"/></a><a href="https://twitter.com/StonedeGod"><img src={twitter} alt="Twitter"/></a></span></div>
            </div>
        </div>
    )
}

export default Layout;