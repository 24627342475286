import Layout from './component/Layout';
import Connexion from './pages/Connexion';
import Staking from './pages/Staking';
import { useGetLoginInfo } from '@elrondnetwork/dapp-core';

function App() {
  const {isLoggedIn} = useGetLoginInfo()

  return (

      <Layout>
        {
          isLoggedIn ? <Staking/> : <Connexion/>
        }
      </Layout>
  );
}

export default App;
