import styles from './Button.module.scss'
function Button({children,onClick,className,forbidden}){
    return(
        <>
        {
            forbidden ?
                <div className={styles.forbidden + " "+className} >{children}</div>
            :
                <div className={styles.button + " "+className} onClick={onClick}>{children}</div>

        }
        </>
    )
}
export default Button;