import styles from "./MaiarConnect.module.scss"
import QRCode from "react-qr-code";


const MaiarConnect = (props) => {
    return(
        <>
            <div className="bg" onClick={props.onClose}></div>

            
            <div className={styles.popup}>
                <a href={props.uriDeepLink}><div><QRCode value={props.uriQrCode}/></div><div><span>Maiar Login</span></div></a>
            </div>
            
        </>
    )
}

export default MaiarConnect;