import styles from "./Login.module.scss"
import stylesButton from "../Button/Button.module.scss"
import Button from "../Button"
import {loginServices,DappUI} from "@elrondnetwork/dapp-core";
import { useState } from "react"
import MaiarConnect from "../MaiarConnect";


const Login = ({onClose}) => {
    const [showMaiar,setShowMaiar] = useState(false)
    const [showLedger,setShowLedger] = useState(false)

    const [triggerFunctionWebWallet] = loginServices.useWebWalletLogin({
        callbackRoute:"/",
        logoutRoute:"/"
    });
    const [triggerFunctionExtension] = loginServices.useExtensionLogin({
        callbackRoute:"/",
        logoutRoute:"/"
    });

    const maiarConnect  = loginServices.useWalletConnectLogin({
        callbackRoute:"/",
        logoutRoute:"/"
    });

    let triggerFunctionMaiar = maiarConnect[0]
    let customLoginReturnType = maiarConnect[2]
    console.log(customLoginReturnType)

    function ledgerLogin(){
        const [triggerFunctionLedger] = loginServices.useLedgerLogin({
            callbackRoute:"/",
            logoutRoute:"/"
        });
        triggerFunctionLedger();
        setShowLedger(true)
    }

    return(
        <>

            <div className={styles.bg} onClick={onClose}></div>
            {(customLoginReturnType.walletConnectUri !== "" && showMaiar) && <MaiarConnect onClose={()=>setShowMaiar(false)} uriQrCode={customLoginReturnType.walletConnectUri} uriDeepLink={customLoginReturnType.uriDeepLink}/>}
            {(showLedger) && <DappUI.LedgerLoginContainer 
                                callbackRoute="/"
                                className="/"
                                shouldRenderDefaultCss= "/"
                                redirectAfterLogin= "/"
                                onClose={()=>setShowLedger(false)}
                            />
            }

            <div className={styles.login}>
                <h2>Login</h2>
                <div>
                    <div className={styles.option}>
                        <Button onClick={()=>triggerFunctionWebWallet()}>Web Wallet</Button>
                        <Button onClick={()=>triggerFunctionExtension()}>Extension</Button>
                        <Button onClick={()=>{triggerFunctionMaiar();setShowMaiar(true)}}>Maiar</Button>
                        
                        <DappUI.LedgerLoginButton
                            callbackRoute="/"
                            buttonClassName={stylesButton.button}
                            loginButtonText="Ledger"
                        />
                        {/*<DappUI.ExtensionLoginButton
                            callbackRoute="/"
                            buttonClassName=""
                            loginButtonText="Extension"
                        />
                        <DappUI.WalletConnectLoginButton
                            callbackRoute="/"
                            buttonClassName=""
                            loginButtonText="Maiar"
                        /> */}
                        {/*<Button>Ledger</Button>*/}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;