import imgGallery from "../../assets/img/19.png"
import frame from "../../assets/img/gallery.png"
import styles from "./Staking.module.scss"
import ButtonStaking from "../../component/ButtonStaking"
import Button from "../../component/Button"
import {useEffect,useState} from "react"
import axios from "axios"
import { useGetAccountInfo,useGetNetworkConfig,refreshAccount,transactionServices,useGetSignedTransactions,useSignTransactions } from '@elrondnetwork/dapp-core';
import {
    Address,
    ContractFunction,
    Query,
    AbiRegistry,
    SmartContractAbi,
    SmartContract,
    ResultsParser,
    U8Value,
    AddressValue
} from '@elrondnetwork/erdjs';
import { ProxyNetworkProvider } from "@elrondnetwork/erdjs-network-providers"
import abiFile from "../../assets/abi/stacking.abi.json";
import BigInt from "big-integer"








function Staking(){
    const { sendTransactions } = transactionServices;
    const [images, setImages] = useState([])
    const [stakedImages, setStakedImages] = useState([])
    const [numberStaked,setNumberStaked] = useState(0)
    const [currentEpoch,setCurrentEpoch] = useState(0)
    const [rewardsComputationTime,setRewardsComputationTime] = useState()
    const [rewards,setRewards] = useState()
    const { address,account } = useGetAccountInfo();
    const { network } = useGetNetworkConfig();
    const [sessionId, setSessionId] = useState(null);
    const {
        callbackRoute,
        transactions,
        error,
        onAbort,
        hasTransactions
      } = useSignTransactions();
    
    useEffect(()=>{
        async function fetchNft(){
            try{
                let response = await axios.get(`https://api.elrond.com/accounts/${address}/nfts?size=10000&identifiers=&collections=STONEDEGOD-f4ba71`)
                setImages(response.data)
            }catch(e){
                console.log(e)
            } 
        }
        const fetchStakedNft = async () => {
            try{
                const contractAddress = process.env.REACT_APP_SMART_CONTRACT
                let abiRegistry = AbiRegistry.create(abiFile);
                let abi = new SmartContractAbi(abiRegistry, ["NftStakingSc"]);
        
                let contract = new SmartContract({ address: new Address(contractAddress), abi: abi });
                const proxy = new ProxyNetworkProvider(network.apiAddress);
                let query= new Query({
                    address: new Address(contractAddress),
                    func: new ContractFunction('getStakedNfts'),
                    args: [new AddressValue(new Address(address))]
                  });
                  
                let queryResponse=await proxy.queryContract(query)
                let endpointDefinition = contract.getEndpoint("getStakedNfts");
                let { firstValue }  = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);
                let stakedNftQueries=""

                for(const item of firstValue.items){
                    let nonce = parseInt(item.value,10).toString(16)
                    if(nonce.length % 2 === 1) nonce="0"+nonce
                    if(stakedNftQueries.length!==0) stakedNftQueries += "%2C"
                    stakedNftQueries += "STONEDEGOD-f4ba71-"+nonce
                }
                if(stakedNftQueries!==""){
                    let response = await axios.get(`https://api.elrond.com/collections/STONEDEGOD-f4ba71/nfts?size=10000&identifiers=${stakedNftQueries}`)
                    setStakedImages(response.data)

                }else{
                    setStakedImages([])
                }

                
                
                
            }catch(e){
                console.log("error fetching staked NFT : "+e)
            }
            
        }

        const fetchRewards = async () => {
            try{
                const contractAddress = process.env.REACT_APP_SMART_CONTRACT
                let abiRegistry = AbiRegistry.create(abiFile);
                let abi = new SmartContractAbi(abiRegistry, ["NftStakingSc"]);
        
                let contract = new SmartContract({ address: new Address(contractAddress), abi: abi });
                const proxy = new ProxyNetworkProvider(network.apiAddress);
                let query= new Query({
                    address: new Address(contractAddress),
                    func: new ContractFunction('getRewardsComputationTimestamp'),
                    args: [new AddressValue(new Address(address))]
                  });
                  
                let queryResponse=await proxy.queryContract(query)
                let endpointDefinition = contract.getEndpoint("getRewardsComputationTimestamp");
                let { firstValue }  = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);


                setRewardsComputationTime(firstValue)
                query= new Query({
                    address: new Address(contractAddress),
                    func: new ContractFunction('getRewards'),
                    args: [new AddressValue(new Address(address))]
                  });
                  
                queryResponse=await proxy.queryContract(query)
                endpointDefinition = contract.getEndpoint("getRewards");
                let response  = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);
                
                setRewards(Math.round(BigInt(response.firstValue.value) * BigInt(100)/ BigInt(10**18) / BigInt(100)))

                response = await axios.get(`https://api.elrond.com/stats`)


                setCurrentEpoch(response.data["epoch"])
                
                
            }catch(e){
                console.log("error fetching staked NFT : "+e)
            }
            
        }
        async function fetchNbStaked(){
            const contractAddress = process.env.REACT_APP_SMART_CONTRACT
            try{
                let response = await axios.get(`https://api.elrond.com/accounts/${contractAddress}/nfts/count?identifiers=STONEDEGOD-f4ba71`)
                setNumberStaked(response.data)
            }catch(e){
                console.log(e)
            } 
        }
        fetchStakedNft()
        fetchNft()
        fetchNbStaked()
        fetchRewards()
        let interval=setInterval(function(){fetchStakedNft();fetchNft()},10000)
        return () => clearInterval(interval);
    },[])

    const getRewards=async ()=>{
        
        const contractAddress = process.env.REACT_APP_SMART_CONTRACT
        const rewardsTransaction = {
            value: "0",
            data: 'claim',
            receiver: contractAddress,
            gasLimit: 10_000_000,
        };
        await refreshAccount();
        const { sessionId, error } = await sendTransactions({
            transactions: rewardsTransaction,
            transactionsDisplayInfo:{
                processingMessage: 'Processing transaction',
                errorMessage: 'An error has occured during transaction',
                successMessage: 'Success'
            },
            redirectAfterSign: false
        });
        if (sessionId != null){
            setSessionId(sessionId);
        }
    }

    const stake=async (nonce)=>{
        
        let nonceHex = nonce.toString(16);
        if(nonceHex.length%2 === 1) nonceHex="0"+nonceHex;
        const rewardsTransaction = {
            value: "0",
            data: `ESDTNFTTransfer@53544f4e454445474f442d663462613731@${nonceHex}@01@00000000000000000500f5255d560407bbf9c1f1dc644b010aa6c4664f35ab10@7374616b65`,
            receiver: address,
            gasLimit: 10_000_000,
        };
        await refreshAccount();
        const { sessionId, error } = await sendTransactions({
            transactions: rewardsTransaction,
            transactionsDisplayInfo:{
                processingMessage: 'Processing transaction',
                errorMessage: 'An error has occured during transaction',
                successMessage: 'Success'
            },
            redirectAfterSign: false
        });
        if (sessionId != null){
            setSessionId(sessionId);
        }
    }

    const unstake=async (tokenId,nonce)=>{
        const contractAddress = process.env.REACT_APP_SMART_CONTRACT;

        let nonceHex = nonce.toString(16);
        if(nonceHex.length%2 === 1) nonceHex="0"+nonceHex;
        const unstakeTransaction = {
            value: "0",
            data: `unstake@${Buffer.from(tokenId).toString('hex')}@${nonceHex}`,
            receiver: contractAddress,
            gasLimit: 10_000_000,
        };
        await refreshAccount();
        const { sessionId, error } = await sendTransactions({
            transactions: unstakeTransaction,
            transactionsDisplayInfo:{
                processingMessage: 'Processing transaction',
                errorMessage: 'An error has occured during transaction',
                successMessage: 'Success'
            },
            redirectAfterSign: false
        });
        if (sessionId != null){
            setSessionId(sessionId);
        }
    }

    const rewardsToShow=rewards+((currentEpoch - rewardsComputationTime - 1)*stakedImages.length*10)

    return( 
        <div>
            <div className={styles.gallery}>
                {
                    stakedImages.map((nft,index)=>
                        <div key={index} className={styles.nft}>
                            <div>
                                <img src={frame} alt="frame"/>
                                <img src={nft.media[0].url} alt="nft"/>
                            </div>
                            <div>{nft.identifier}</div>
                            <div><ButtonStaking onClick={()=>unstake(nft.collection,nft.nonce)}>Unstake</ButtonStaking></div>
                        </div>
                    )
                }
                {
                    images.map((nft,index)=>
                        <div key={index} className={styles.nft}>
                            <div>
                                <img src={frame} alt="frame"/>
                                <img src={nft.media[0].url} alt="nft"/>
                            </div>
                            <div>{nft.identifier}</div>
                            <div><ButtonStaking onClick={()=>stake(nft.nonce)}>Stake</ButtonStaking></div>
                        </div>
                    )
                }
            </div>
            <div className={styles.actions}>
                <p>Total staked : {numberStaked}</p>
                <p>My NFT staked : {stakedImages.length}</p>
                <p>To claim : {rewardsToShow>0 ? rewardsToShow : 0} $EGOD</p>
                <Button forbidden={rewardsToShow<=0} onClick={getRewards}>Get rewards</Button>
            </div>
        </div>
    )

}

export default Staking;