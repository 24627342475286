import colTop from "../../assets/img/col-top.png"
import colBottom from "../../assets/img/col-bottom.png"
import styles from "./Connexion.module.scss"
import Button from "../../component/Button"
import PopupConnexion from '../../component/LoginPopup'
import {useState} from "react"
function Connexion(){
    const [popup,setPopup] = useState(false)

    return(
        <>
        {popup && <PopupConnexion onClose={()=>setPopup(false)}/>}
        <div className={styles.connexion }>
            <div className={styles.body }>
                <div className={styles.column }>
                    <img src={colTop} alt="Column"/>
                </div>
                <div>
                    <p>Here you can stake your Stoned eGod to earn $EGOD</p>
                </div>
                <div>
                    <Button onClick={()=>setPopup(true)}>Connect wallet</Button>
                </div>
                <div className={styles.column }>
                    <img src={colBottom} alt="Column"/>
                </div>
            </div>
        </div>
        </>
    )
}

export default Connexion;