import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DappProvider,DappUI } from '@elrondnetwork/dapp-core';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import '@elrondnetwork/dapp-core/dist/index.css';



ReactDOM.render(
  <React.StrictMode>
    <DappProvider
      environment={process.env.REACT_APP_NETWORK}
      customNetworkConfig={{ name: 'customConfig', apiTimeout: 6000 }}
    >
      <DappUI.TransactionsToastList />
      <Router>
        <Routes>
          <Route path="/" element={<App />}/>
          <Route path="*" element={<App />}/>
        </Routes>       
      </Router>
      
    </DappProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
